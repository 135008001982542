<template>
  <div
    class="py-40 px-20 md:px-50 fixed bottom-0 w-full z-[99999] shadow-[4px_0_20px_0_rgba(0,0,0,0.15)]"
    :class="isBettingen ? 'bg-gray-100' : 'bg-green-100'"
  >
    <div
      class="lg:container flex flex-col md:flex-row md:items-center gap-25 md:gap-20"
    >
      <div class="flex-1">
        <p class="text-lg mb-10">
          {{
            $texts(
              'cookieBanner.introduction',
              'Zu statistischen Zwecken und zur Verbesserung unseres Angebots werden Informationen, die uns ihr Zugangsgerät und ihr Browser beim Aufruf dieser Webseite automatisch mitteilt, erfasst und bearbeitet. Dieser Vorgang erfolgt anonymisiert und entfernt somit den Bezug zu Ihrer Person.',
            )
          }}
        </p>
        <p class="text-lg mb-10">
          {{
            $texts(
              'cookieBanner.noThirdParties',
              'Ihre Daten werden ausschliesslich von uns verwendet. Eine Weitergabe an Dritte erfolgt nicht.',
            )
          }}
        </p>
        <div class="text-lg" v-html="privacyPolicyText" />
      </div>
      <div class="flex flex-col gap-10 items-start">
        <button class="button is-strong is-check" @click="acceptAll">
          {{ $texts('cookieBanner.acceptAll', 'Weiter mit allen Cookies') }}
        </button>

        <button class="button is-check" @click="acceptPartial">
          {{
            $texts(
              'cookieBanner.acceptNecessary',
              'Weiter mit erforderlichen Cookies',
            )
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { acceptPartial, acceptAll } = useConsentCookie()

const router = useRouter()
const { $texts } = useNuxtApp()

const privacyPolicyText = computed(() => {
  const privacyPolicyPath = router.resolve({ name: 'privacy-policy' }).fullPath

  const privacyPolicyLabel = $texts(
    'cookieBanner.privacyPolicy',
    'Datenschutzerklärung',
  )
  const fullText = $texts(
    'cookieBanner.privacyPolicyLink',
    'Weitere Informationen finden Sie in unserer @link.',
  )
  return fullText.replace(
    '@link',
    `<a href="${privacyPolicyPath}" class="link">${privacyPolicyLabel}</a>`,
  )
})

const { isBettingen } = useCurrentSite()
</script>

<style lang="postcss">
.banner-sticky-enter-active,
.banner-sticky-leave-active {
  @apply transition origin-top duration-300;
}
.banner-sticky-enter-from,
.banner-sticky-leave-to {
  @apply transform translate-y-full;
}
</style>
